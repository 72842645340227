
// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
@import "../node_modules/ng-zorro-antd/ng-zorro-antd.less";

// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

// @primary-color: #1890ff;
// -------- Colors -----------
@primary-color: #006634;
@secondary-color: #8dbf2b;



.ant-steps-item-container{
    @media (max-width:48em) {
        text-align: center;
      }
}

.ant-steps-item-title{
    @media (max-width:48em) {
      display: none;
    }
  }

.title-responsive{
    display: none;
    @media (max-width:48em) {
        display: block;
      }
}

.auth0-lock-name{
    display: none !important;
}

.navbar>.container-fluid{
    @media (max-width:48em) {
        justify-content: center;
      }
}


.navbar-brand{
    img{
        @media (max-width:48em) {
        display: flex !important;
        margin: 0 auto
        }
    }
}


.ant-input-group-addon{
  background-color: white;
  border: none;
}

.ant-modal-close{
  position: absolute;
  /* top: -3rem; */
  right: 0;
  z-index: 10;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: white;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
}

.ant-timeline-item {
  position: relative;
  margin: 17px;
  padding-bottom: 20px;
  font-size: 14px;
  list-style: none;
}


.ant-timeline-item-tail {
  position: absolute;
  top: 10px;
  left: 4px;
  height: calc(100% + 10px);
  border-left: 2px solid #f0f0f0;
}