/* You can add global styles to this file, and also import other style files */
@font-face {
    font-family: "Montserrat";
    src: local("Montserrat"),
      url(./assets/fonts/Montserrat-Regular.ttf) format("truetype");
  }
  
  @font-face {
    font-family: "Nunito";
    src: local("Nunito"), url(./assets/fonts/Nunito-Bold.ttf) format("truetype");
  }

  .info-circle{
    color: #8EC045;
    margin-left: 0.5rem;
}

.bienvenido-result{
  .ant-result-icon {
      display: none !important;
  }
}

.container{
  .ant-radio-inner{
      top: 2rem;
      left: 5rem;
      z-index: 1200;
  }
  .ant-radio-input{
    top: 2rem;
    left: 5rem;
    z-index: 1200;
  }
}

.cards-headquarters{
  flex-flow: nowrap;

  @media (max-width:48em) {
    flex-flow: row wrap;
    
  }
}

.main-menu-card{

  .ant-card-head-wrapper{
    @media (max-width:48em) {
      display: inline-grid
      
    }
  }
}